import React from 'react';
import { Listbox } from '@headlessui/react';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import ContractSolid from '@components/ui/contractSolid';
import StarIconSolid from '@components/ui/starIconSolid';
import DocumentIconSolid from '@components/ui/documentIconSolid';
import ChartBarSolidIcon from '@components/ui/chartBarSolidIcon';
import Cookies from 'js-cookie';
import {
  ArrowRightEndOnRectangleIcon,
  ChartBarIcon,
  ChevronDownIcon,
  ClipboardDocumentCheckIcon,
  ClipboardIcon,
  StarIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';

export default function Profile({ open, student }) {
  const router = useRouter();
  const { pathname } = router;

  function logOut() {
    Cookies.remove('edu_token');
    signOut({ callbackUrl: '/' });
  }

  return (
    <Listbox value={''}>
      <div className="cursor-pointer relative flex justify-center lg:justify-start">
        <Listbox.Button
          className={`flex items-center text-sm text-white gap-1 px-3 py-2.5 rounded-lg ${
            pathname === '/' || pathname === '/schools'
              ? 'bg-mainc'
              : open
              ? 'border border-white/40 hover:bg-white/20'
              : 'border border-white/40 hover:bg-white/20'
          }`}
        >
          <UserCircleIcon className="h-5 text-white/90" />

          <div className="flex flex-wrap text-white gap-1">
            <p className="font-light">{student?.last_name}</p>
            <b className="uppercase">{student?.first_name}</b>
          </div>
          <ChevronDownIcon className="h-4 text-white" />
        </Listbox.Button>
        <Listbox.Options
          className={
            'absolute mt-10 w-60 bg-white rounded-sm shadow-lg right-0 text-text'
          }
        >
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <a
                href="/user"
                className={`py-4 px-5 w-full hover:text-bluec hover:bg-disabled ${
                  (pathname === '/user' || pathname === '/user/other') &&
                  'bg-disabled text-bluec '
                }`}
              >
                <div className="flex items-center justify-start gap-x-2 text-medium">
                  <UserCircleIcon className="h-5 w-5" /> Оюутны мэдээлэл
                </div>
              </a>
            )}
          </Listbox.Option>
          <hr />
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <a
                href="/step"
                className={`py-4 px-5 w-full hover:bg-disabled hover:text-bluec ${
                  pathname === '/step' && 'bg-disabled text-bluec'
                }`}
              >
                <div className="flex items-center justify-start gap-x-2 text-medium">
                  {selected ? (
                    <ChartBarSolidIcon class="h-5 w-5" />
                  ) : (
                    <ChartBarIcon className="h-5 w-5" />
                  )}{' '}
                  Алхам
                </div>
              </a>
            )}
          </Listbox.Option>
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <a
                href="/mortgage"
                className={`py-4 px-5 w-full hover:bg-disabled hover:text-bluec ${
                  pathname === '/mortgage' && 'bg-disabled text-bluec'
                }`}
              >
                <div className="flex items-center justify-start gap-x-2 text-medium">
                  {selected ? (
                    <DocumentIconSolid class="h-5 w-5" />
                  ) : (
                    <ClipboardIcon className="h-5 w-5" />
                  )}{' '}
                  Барьцаа
                </div>
              </a>
            )}
          </Listbox.Option>
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <a
                href="/point"
                className={`py-4 px-5 w-full hover:text-bluec hover:bg-disabled ${
                  pathname === '/point' && 'bg-disabled text-bluec'
                }`}
              >
                <div className="flex items-center justify-start gap-x-2 text-medium">
                  {selected ? (
                    <StarIconSolid class="h-5 w-5" />
                  ) : (
                    <StarIcon className="h-5 w-5" />
                  )}{' '}
                  Дүн
                </div>
              </a>
            )}
          </Listbox.Option>
          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <a
                href="/contract"
                className={`py-4 px-5 w-full hover:bg-disabled hover:text-bluec ${
                  pathname === '/contract' && 'bg-disabled text-bluec'
                }`}
              >
                <div className="flex items-center justify-start gap-x-2 text-medium">
                  {selected ? (
                    <ContractSolid class="h-5 w-5" />
                  ) : (
                    <ClipboardDocumentCheckIcon className="h-5 w-5" />
                  )}{' '}
                  Гэрээ
                </div>
              </a>
            )}
          </Listbox.Option>

          <Listbox.Option
            className={({ active }) =>
              `${active ? '' : ''}relative flex items-center`
            }
            value={''}
          >
            {({ selected }) => (
              <div
                onClick={() => logOut()}
                className="py-4 px-5 w-full hover:bg-red-50 hover:text-urlHover"
              >
                <div className="flex items-center justify-start gap-x-2 text-medium font-semibold">
                  <ArrowRightEndOnRectangleIcon className="text-red-400 h-5 w-5" />
                  <span className="text-red-400">Гарах</span>
                </div>
              </div>
            )}
          </Listbox.Option>
        </Listbox.Options>
      </div>
    </Listbox>
  );
}

import React from 'react';
import { Listbox } from '@headlessui/react';
import { useRouter } from 'next/router';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { PhoneIcon } from '@heroicons/react/24/solid';

const phones = [
  {
    number: '7011-0503',
    src: 'https://i2.wp.com/edulinellc.mn/wp-content/uploads/2020/08/mn.png?resize=32%2C16',
    dial: '+976',
    href: 'tel:7011-0503',
  },
  {
    number: '010-6632-3266',
    src: 'https://i2.wp.com/edulinellc.mn/wp-content/uploads/2020/08/kr-flag-mini.png?resize=32%2C21',
    dial: '',
    href: 'tel:010-6632-3266',
  },
  {
    number: '84-35-464-9571',
    src: 'https://i0.wp.com/edulinellc.mn/wp-content/uploads/2020/08/vn.png?resize=32%2C21',
    dial: '',
    href: 'tel:84-35-464-9571',
  },
];

export default function Language({ open }) {
  const router = useRouter();
  const { pathname } = router;
  return (
    <Listbox value={''}>
      <div className="cursor-pointer relative flex justify-center lg:justify-start">
        <Listbox.Button
          className={`${'bg-gradient-to-r from-[#ef156d] to-[#fea459]'} flex items-center gap-1 text-sm text-white px-3 py-2.5 rounded-lg`}
        >
          <PhoneIcon className="h-5 w-5" />
          <ChevronDownIcon className="w-4 h-4" />
        </Listbox.Button>
        <Listbox.Options
          className={
            'absolute mt-10 w-44 bg-white rounded-sm shadow-lg right-0'
          }
        >
          {phones.map((phone, i) => (
            <Listbox.Option
              key={i}
              className={({ active }) =>
                `${active ? '' : ''}relative flex items-center`
              }
              value={''}
            >
              {({ selected }) => (
                <a
                  href={phone.href}
                  className="py-3 px-4 w-full hover:bg-disabled hover:text-urlHover"
                >
                  <span className="flex items-center justify-start gap-x-2 text-medium">
                    <img src={phone.src} alt="" className="h-3.5 rounded-sm" />
                    <p>{phone.dial}</p>
                    {phone.number}
                  </span>
                </a>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
